* {
  box-sizing: border-box;
  color: #fff;
  font-family: Raleway, sans-serif;
}

html, body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  min-height: 100vh;
  background: #000;
}

a {
  border-bottom: 1px dotted;
  text-decoration: none;
}

a:hover {
  border-bottom: 1px solid;
}

section {
  padding: 3rem;
}

.splash {
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  display: flex;
}

.splash img {
  max-width: 25rem;
  width: 100%;
  image-rendering: pixelated;
}

/*# sourceMappingURL=index.2d88f631.css.map */
