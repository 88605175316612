* {
  box-sizing: border-box;
  color: #fff;
  font-family: 'Raleway', sans-serif;
}

html,
body {
  margin: 0;
  padding: 0;
  font-size: 16px;
}

body {
  background: #000;
  min-height: 100vh;
}

a {
  text-decoration: none;
  border-bottom: 1px dotted;
}

a:hover {
  border-bottom: 1px solid;
}

section {
  padding: 3rem;
}

.splash {
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  padding: 2rem;
}

.splash img {
  max-width: 25rem;
  width: 100%;
  image-rendering: pixelated;
}
